import Vue      from 'vue';
import Vuex     from 'vuex';

// Modulos Vuex
import m_app      from '@app/stores/app';
import m_auth     from '@app/stores/auth';
import m_leiloes  from '@app/stores/leiloes';
import m_carrinho from '@app/stores/carrinho';
import m_parques  from '@app/stores/parques';

// Registrar modulo vue
Vue.use(Vuex);

const store = new Vuex.Store({ 
    modules: {
        app      : m_app,
        auth     : m_auth,
        leiloes  : m_leiloes,
        carrinho : m_carrinho,
        parques  : m_parques,
    }
 });

export default store;