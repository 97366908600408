<template>
  <div class="modal fadex" tabindex="-1" id="modal">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title">{{ titulo }}</h5>
          <p v-if="descricao">{{ descricao }}</p>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <form @submit.prevent="executar">

            <div class="modal-body">
                <slot></slot>
            </div>

            <div class="modal-footer">
                <t-button type="submit" class="btn btn-primary" :carregando="executando" :mensagem="acaoExecutando">{{ acaoPrincipal }}</t-button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            </div>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            required: true,
        },

        descricao: {
            type: String,
            default: null,
        },

        acaoPrincipal: {
            type: String,
            default: 'Salvar'
        },

        acaoExecutando: {
            type: String,
            default: 'Salvando'
        },

        executando: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            modal: null,
            salvando: false,
        };
    },

    mounted: function() {
        this.modal = new bootstrap.Modal('#modal');
    },

    methods: {
        /**
         * Abrir forma
         */
        show() {
            var $this = this;

            this.modal.show();

            setTimeout(() => {
                $this.$emit('show');
            }, 150);
        },

        /**
         * Salvar
         */
        executar() {
            this.$emit('enter');                
        }
    }
}
</script>