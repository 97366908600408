    <template>
    <div class="leilao-item-box col-10 offset-1 offset-sm-0 col-sm-6 col-md-4 col-lg-3" v-if="leilao">
        <div class="leilao-item">
            <div class="foto">
                <router-link :to="{ name: 'leilao', params: { lid: leilao.id, slug: leilao.slug }}">
                    <img :src="leilao.foto_src">
                </router-link>

                <div class="valor-atual">
                    <div class="old">{{ leilao.valor_mercado | num_format('$ 0.00') }}</div>
                    {{ leilao.controles.valor_atual | num_format('$ 0.00') }}
                </div>

                <div class="parque">
                    <router-link :to="{ name: 'parque', params: { pns: leilao.parque.ns } }">
                        <img :src="leilao.parque.logo_src" />
                    </router-link>

                </div>

            </div>
            <div class="box">
                <h3 v-if="!leilao.arrematado">INICIA EM {{ leilao.data_inicio | date_format('DD/MM/YY HH:mm:ss') }}</h3>


                <h3 v-if="leilao.arrematado">
                    INICIOU EM {{ leilao.data_inicio | date_format('DD/MM/YY HH:mm:ss') }}<br>
                    TERMINOU EM {{ leilao.data_inicio | date_format('DD/MM/YY HH:mm:ss') }}
                </h3>

                <h2>
                    <router-link :to="{ name: 'leilao', params: { lid: leilao.id, slug: leilao.slug }}">{{ leilao.titulo }}</router-link>
                </h2>

                <t-lance-btn :leilao="leilao" tamanho="pequeno" class="mt-3" v-model="leilao.segundos" v-if="!finalizado">
                    <div class="usuario">
                        {{ leilao.controles.ultimo_usuario ? leilao.controles.ultimo_usuario : '---' }}
                    </div>
                </t-lance-btn>

                <div class="resultado" v-if="finalizado">
                    Ganhador:
                    <div class="usuario fs-5">
                        {{ leilao.controles.ultimo_usuario ? leilao.controles.ultimo_usuario : '---' }}
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import TLanceBtn from './btn-lance.vue';

export default {
    components: {
        TLanceBtn,
    },

    props: {
        leilao: {
            required: true,
        },

        finalizado: {
            type: Boolean,
            default: false,
        }
    }
}
</script>