<template>
    <div :class="'contador contador-' + tamanho">
        <ul>

            <li v-for="(d, index) in digitos" :key="index" :class="cores">
                {{ d }}
            </li>

        </ul>

    </div>
</template>
<script>
export default {
    
    props: {

        ativo: {
            type: Boolean,
            default: false,
        },

        maxSegundos: {
            type: Number,
            default: 15,
        },

        tamanho: {
            type: String,
            default: 'normal'
        },

        segundos: {
            type: Number,
            require: true,
        },
    },

    computed: {

        cores() {

            // Ainda não ativo?
            if (!this.ativo) {
                return 'cinza';
            }

            if (this.segundos <= 3) {
                return 'vermelho';
            }

            if (this.segundos > 3 && this.segundos <= 10 ) {
                return 'amarelo';
            }

            return 'verde';
        },

        colunas() {
            if (this.maxSegundos > 999) {
                return 4;
            }

            if (this.maxSegundos > 99) {
                return 3;
            }

            return 2;
        },

        digitos() {
            var lista = [];
            for (var c = 1; c <= this.colunas; c++) {
                lista.push(this.coluna_valor(c));
            }

            return lista;
        }
    },

    methods: {

        coluna_valor(col){

            col = this.colunas - (col-1);
            
            if(col == 1)
                return Math.trunc((Math.trunc(this.segundos / 10) - (this.segundos * 0.1)) * 10 * -1);
            
            else if(col == 2)
                return Math.trunc((Math.trunc(this.segundos / 100) - (this.segundos * 0.01)) * 10 * -1);
            
            else if(col == 3)
                return Math.trunc((Math.trunc(this.segundos / 1000) - (this.segundos * 0.001)) * 10 * -1);
            
            else if(col == 4)
                return Math.trunc((Math.trunc(this.segundos / 10000) - (this.segundos * 0.0001)) * 10 * -1);                             

            else
                return  '_';
                
        },
    }
}
</script>