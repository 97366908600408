import { LeilaoSocket } from './utils/leilao_socket';
import LeilaoProxy from './utils/leilao_proxy';

var socket  = null;

export default {
    namespaced: true,

    state: {
        leiloes: {},
        registrandoLance: false,
        serverConectado: false,
    },

    mutations: {

        SET_LEILOES(state, value) {
            console.log('LEILOES UPDATE');              
            //window.xx = this;

            // Atribuir a nova lista
            for (var key in value) {
                var leilaoItem = value[key];

                if (!state.leiloes[key]) {
                    this._vm.$set(state.leiloes, key, new LeilaoProxy(leilaoItem));
                    //state.leiloes[key] = ;
                } else {
                    state.leiloes[key].setTarget(leilaoItem);
                }
            }

            // Excluir os itens que não tem mais
            for (var key in state.leiloes) {
                if (!value.hasOwnProperty(key)) {
                    state.leiloes[key].reset();
                    this._vm.$delete(state.leiloes, key);
                    //delete state.leiloes[key];
                }
            }

            //console.log('LU ', state.leiloes);
        },

        SET_STATUSLANCE(state, value) {
            state.registrandoLance = value;
        },

        SET_STATUS_SERVER(state, value) {
            state.serverConectado = value;
        }
    },

    actions: {
        async iniciar({ commit}) {
            socket = new LeilaoSocket();

            socket.events.on('leilao-update', (data) => {
                commit('SET_LEILOES', data);
                commit('SET_STATUSLANCE', false);
            });

            socket.events.on('erro', (erro) => {
                commit('SET_STATUSLANCE', false);
            });
            
            socket.events.on('usuario-lances-udate', (data) => {
                this.commit('auth/SET_LANCES_USER', data);
            });            

            socket.events.on('entrou', () => {
                commit('SET_STATUS_SERVER', true);
            });

            socket.events.on('saiu', () => {
                commit('SET_STATUS_SERVER', false);
            });
        },

        async setToken() {
            socket.setToken();
        },

        async darLance({commit}, args) {
            commit('SET_STATUSLANCE', true);
            await socket.darLance(args.leilao_id, args.segundos);
        }
    }
};