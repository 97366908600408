import framework from 'rhinojs/webcore';
import { PER_ADMIN } from '@app/modules/permissoes';

var routes = [
    // 404
    framework.router.error404(() => import('@views/erros/404.vue'), '404 - Página não encontrada'),

    // Auth
    framework.router.make('/login', 'login', 'Login').guest().page(() => import('@views/auth/login.vue')),
    framework.router.make('/esquecisenha', 'esquecisenha', 'Esqueci a senha').guest().page(() => import('@views/auth/esqueci_senha.vue')),
    framework.router.make('/trocarsenha/:token?', 'trocarsenha', 'Trocar senha').guest().page(() => import('@views/auth/trocar_senha.vue')),
    
    // Registro
    framework.router.make('/cadastrar', 'cadastrar', 'Cadastre-se').guest().page(() => import('@views/register/cadastrar.vue')),
    framework.router.make('/cadastrar/ativar/:codigo?', 'cadastrar.ativar', 'Ativar código').guest().page(() => import('@views/register/ativar.vue')),
    framework.router.make('/i/:codigo', 'indicacao', 'Indicação').page(() => import('@views/register/indicacao.vue')),

    // Geral
    framework.router.make('/',                 'home').page(() => import('@views/geral/home.vue')),
    framework.router.make('/em-andamento',     'andamento', 'Leilões em andamento').page(() => import('@views/geral/andamento.vue')),
    framework.router.make('/arrematados',      'arrematados', 'Arrematados').page(() => import('@views/geral/arrematados.vue')),
    framework.router.make('/como-funciona',    'como-funciona', 'Como funciona').page(() => import('@views/geral/como-funciona.vue')),
    framework.router.make('/faq',              'faq', 'FAQ').page(() => import('@views/geral/faq.vue')),
    framework.router.make('/termos-condicoes', 'termos', 'Termos e Condições').page(() => import('@views/geral/termos.vue')),
    framework.router.make('/parques',          'parques', 'Parques').page(() => import('@views/geral/parques.vue')),
    framework.router.make('/parques/:pns',     'parque', 'Parque').page(() => import('@views/geral/parque.vue')),
    framework.router.make('/contato',          'contato', 'Contato').page(() => import('@views/geral/contato.vue')),

    // Comprar
    framework.router.make('/comprar',               'comprar', 'Comprar lances').page(() => import('@views/comprar/produtos.vue')),
    framework.router.make('/comprar/obrigado',      'obrigado', 'Obrigado pela compra').auth().page(() => import('@views/comprar/obrigado.vue')),

    // Checkout Lances
    framework.router.make('/pacotes/checkout',       'checkout.pacotes', 'Checkout').auth().page(() => import('@views/checkout/pacotes/pacotes_checkout.vue')),
    framework.router.make('/pacotes/checkout/pagar', 'checkout.pacotes.pagar', 'Checkout Pagar').auth().page(() => import('@views/checkout/pacotes/pacotes_pagar.vue')),

    // Checkout Arremates
    framework.router.make('/arremates/checkout/:lid',       'checkout.arremates', 'Checkout Arremate').auth().page(() => import('@views/checkout/arremates/arremates_checkout.vue')),
    framework.router.make('/arremates/checkout/:lid/pagar', 'checkout.arremates.pagar', 'Checkout Arremate Pagar').auth().page(() => import('@views/checkout/arremates/arremates_pagar.vue')),
    framework.router.make('/arremates/:lid/vouchers',       'arremate.vouchers', 'Voucher').auth().page(() => import('@views/perfil/voucher.vue')),
    
    // Perfil usuário
    framework.router.make('/meu-perfil/:tid',       'perfil', 'Meu Perfil').auth().page(() => import('@views/perfil/perfil.vue')),
    
    // Leilao
    framework.router.make('/leiloes/:slug/:lid', 'leilao', 'Leilão').page(() => import('@views/leilao/leilao.vue')),
    
    // Admin
    framework.router.make('/admin/:tid?',       'admin', 'Admin').auth().meta('can', PER_ADMIN).page(() => import('@views/admin/admin.vue')),
];

export default routes