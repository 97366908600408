import sdk from "@app/modules/sdk";

export default {
    namespaced: true,

    state: {
        user: null,
    },

    mutations: {

        /**
         * Set usuario logado ou null
         */
        SET_USER(state, value) {
            state.user = value;
        },

        /**
         * Set lances usuario
         */
        SET_LANCES_USER(state, value) {
            if (!state.user) {
                return;
            }

            state.user.qtdade_lances = value.lances;
            state.user.qtdade_bonus  = value.bonus;
        },
    },

    actions: {
        async carregar({commit}) {
            // Carregar usuario da API
            try {
                var at = sdk.getAccessToken();
                if (!at) {
                    throw "NAO LOGADO";
                }
                var ret = await sdk.get('auth/usuario');

                //console.log('COM USUARIO', ret);
                // Guardar usuario
                commit('SET_USER', ret);

                return ret;
            } catch (err) {
                //console.log('SEM USUARIO', err);
                commit('SET_USER', {});
                return {};
            }
        }
    }
};