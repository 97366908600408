import store from "@app/modules/stores";

class LeilaoProxy
{
    constructor(target, iniciarTimer = true) {
        var $this = this;

        this.$target   = target;
        this.$segundos = target.controles.segundos;

        // Publicar propriedades do target
        for (var key in target) {
            var getKey = (attr) => {
                return {
                    get() {
                        return this.$target[attr];        
                    }
                }
            }

            Object.defineProperty(this, key, getKey(key) );
        }

        // Iniciar contador
        if (iniciarTimer) {
            this.$idTimer = setInterval(() => {
                $this.$contar();
            }, 1100);
        }
    }

    /**
     * Retorna a data inicio do leilao em Date.
     * @returns { Date }
     */
    get dataInicio() {
        return new Date(this.$target.data_inicio);
    }

    /**
     * Retorna se o leilão iniciou.
     * @returns {Boolean}
     */
    get iniciou() {
        return (this.dataInicio <= store.state.app.horaServidor);
    }

    /**
     * Retorna se o leilão já foi arrematado.
     * @returns {Boolean}
     */
    get arrematado() {
        return ((this.$segundos == 0) || (this.$target.situacao == 'con'));
    }

    /**
     * Retorna se leilao esta ativo.
     * @returns {Boolean}
     */
    get ativo() {
        if (this.arrematado) {
            return false;
        }

        if (!this.iniciou) {
            return false;
        }

        return true;    
    }

    /**
     * Retorna a qtdade de segundos restantes do leilao.
     * @returns { Number }
     */
    get segundos() {
        if (!this.iniciou) {
            return this.$target.cronometro_max;
        }

        return this.$segundos;
    }

    /**
     * Contar os segundos faltantes.
     */
    $contar() {
        //console.log('CONTAR 3 ', this.id, this.__segundos);

        // Se não tiver ativo ignorar
        if (!this.ativo) {
            return;
        }

        // Verificar se deve pagar no 1
        if (this.$segundos <= 1) {
            return;
        }

        // Calcular segundos            
        if (this.$segundos > 1) {
            this.$segundos -= 1;
        } else {
            this.$segundos = this.$target.cronometro_max;
        }
    }

    reset() {
        if (this.$idTimer) {
            clearInterval(this.$idTimer);
        }
    }

    setTarget(target) {
        this.$target = target;
        this.$segundos = this.$target.controles.segundos;
    }
}

export default LeilaoProxy;