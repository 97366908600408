import Vue from 'vue';

// Layouts
import AppDlgLayout from '@views/layouts/dlg.vue';
import AppMainLayout from '@views/layouts/main.vue';

Vue.component('app-dlg-layout', AppDlgLayout);
Vue.component('app-main-layout', AppMainLayout);

// Componentes
import AppContator from '@views/componentes/contador.vue';
import AppHoraServidor from '@views/componentes/hora_servidor.vue';
import AppStatusSocket from '@views/componentes/status_socket.vue';
import AppModal from '@views/componentes/modal.vue';
import AppPaginacao from '@views/componentes/paginacao.vue';
import AppPageLoading from '@views/componentes/page_loading.vue';
import AppPageNoRecs from '@views/componentes/page_norecs.vue';
import AppLeiloesLista from '@views/componentes/leiloes-lista.vue';
import AppLeilaoItem from '@views/leilao/box-item.vue';

Vue.component('app-contador',      AppContator);
Vue.component('app-hora-servidor', AppHoraServidor);
Vue.component('app-status-socket', AppStatusSocket);
Vue.component('app-modal',         AppModal);
Vue.component('app-paginacao',     AppPaginacao);
Vue.component('app-page-loading',  AppPageLoading);
Vue.component('app-page-norecs',   AppPageNoRecs);
Vue.component('app-leiloes-lista', AppLeiloesLista);
Vue.component('app-leilao-item',   AppLeilaoItem);

export default {};