const carbon = require('rhinojs/support/carbon');
import sdk from "@app/modules/sdk";

var __ultimaPing = null;

export default {
    namespaced: true,

    state: {
        horaServidor: null,
    },

    mutations: {

        /**
         * Set Hora
         */
        SET_HORA(state, value) {
            state.horaServidor  = value;
        }
    },

    actions: {
        async iniciar({commit, dispatch}) {
            console.log('APP INICIAR');

            // Atribuir a hora atual do servidor.
            var config = await sdk.get('portal/config');
            var hora = new Date(config.hora_servidor);

            commit('SET_HORA', hora);
            __ultimaPing = new Date();

            // Iniciar controle de hora
            setInterval(() => {
                dispatch('calcSegundos');
            }, 1000);
        },

        calcSegundos({state, commit}) {
            var agora = new Date();

            // Verificar diferença de segundos
            var diff = carbon.subtract(agora, __ultimaPing);
            var segs = diff.toSeconds();
        
            // Adicionar os segundos passados na hora do servidor
            var hora = carbon.addSeconds(state.horaServidor, segs);

            // Atribuir a nova hora do servidor
            commit('SET_HORA', hora);        

            // Registrar ultimo ping        
            __ultimaPing = agora;
        
        }
    }
};