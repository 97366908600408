import sdk from "@app/modules/sdk";

export default {
    namespaced: true,

    state: {
        produto     : null,
        produtos    : [],
    },

    mutations: {

        SET_PRODUTOS(state, value) {
            state.produtos = value;
        },

        SET_PRODUTO(state, value) {
            state.produto = value;
        }
    },

    actions: {
        async carregarProdutos({commit}) {
            // Carregarprodutos da API
            try {
                var ret = await sdk.get('checkout/produtos');

                // Guardar lista de produtos
                commit('SET_PRODUTOS', ret);

                return ret;
            } catch (err) {
                commit('SET_PRODUTOS', []);
                return [];
            }
        },

        setProduto({commit}, prod) {
            commit('SET_PRODUTO', prod);
        }
    }
};