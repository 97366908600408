<template>
    <div class="t-layout-dlg">

        <header v-if="header">
            <div class="row">
                <div class="col-12 d-flex justify-content-center py-3">
                    <router-link :to="{ name: 'home' }">
                        <img src="https://static.netforce.com.br/leilaoingressos/imgs/logo.png" id="logo" />
                    </router-link>
                </div>
            </div>
        </header>
        

        <header v-if="false">
            <img :src="logo" class="logo"/>
            <h2 class="fw-light" v-html="titulo"></h2>
        </header>

        <div class="conteudo">
            <slot></slot>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: Boolean,
            default : true,
        },

        titulo: {
            type: String,
        },

        logo: {
            type    : String,
            default : 'https://static.netforce.com.br/imgs/n.png'
        }
    }
}
</script>