<template>
    <div class="leiloes-lista">
        <div class="row">
            <app-leilao-item v-for="(leilao, index) in lista" :key="index" :leilao="leilao"/>
        </div>

        <div class="mt-3 text-center" v-if="((max > 0) && (lista.length > max))">
            <router-link :to="{ name: 'andamento' }" class="btn btn-primary px-4 py-2">Ver todos</router-link>
        </div>

    </div>

</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        max: {
            default: 0
        }
    },

    computed: {
        ...mapState('leiloes', ['leiloes']),

        lista() {
            var itens = Object.values(this.leiloes);

            itens.sort((a, b) => {
                if (a.data_inicio < b.data_inicio) {
                    return -1;
                }

                if (a.data_inicio > b.data_inicio) {
                    return 1;
                }

                return 0;
            });

            // Retornar a lista para manter a referencia
            var lista = {};
            for (var i = 0; i < itens.length; i++) {
                this.$set(lista, itens[i].id, itens[i]);
            }

            return lista;
        }
    }    
}
</script>