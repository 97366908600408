<template>
    <div class="d-print-none">
        <header>
            <div class="container py-4 py-md-2">
            <div class="row">
                <!-- header.logo -->
                <div class="col-11 col-sm-5 col-md-4 d-flex justify-content-center align-items-center">
                    <router-link to="/">
                        <img src="https://static.netforce.com.br/leilaoingressos/imgs/logo.png" />
                    </router-link>
                </div>

                <!-- header.menu -->
                <div class="col-1 d-flex justify-content-end d-sm-none align-items-center">
                    <button type="button" class="btn btn-link" @click="sideLeftClick">
                        <i class="ms-Icon ms-Icon--CollapseMenu"></i>
                    </button>
                </div>

                <!-- header.comprar -->
                <div class="d-none col-md-4 d-md-flex justify-content-center align-items-center flex-column">
                    
                    <router-link :to="{ name: 'comprar' }">
                        <img src="https://static.netforce.com.br/leilaoingressos/imgs/comprar.png" class="btn" />
                    </router-link>

                    <app-hora-servidor />
                </div>

                <!-- header.menu -->
                <div class="col-12 col-sm-5 col-md-4 d-flex justify-content-center align-items-center">

                    <!-- Menu Visitante -->
                    <nav v-if="!usuario" class="mt-2">
                        <router-link :to="{ name: 'cadastrar' }">Cadastre-se</router-link>
                        <router-link :to="{ name: 'login' }">Entrar</router-link>
                    </nav>

                    <!-- Menu Usuário -->
                    <nav class="nav-item dropdown menu-usuario" v-if="usuario">
                        <a class="nav-linkx dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>Olá <strong>{{ usuario.apelido }}</strong></span>
                        </a>

                        <app-hora-servidor class="d-flex d-md-none"/>

                        <div class="usuario-status">
                            <div class="row">
                                <div class="col-6 text-center lances">Lances:<div class="value">{{ usuario.qtdade_lances | num_format('0,0') }}</div></div>
                                <div class="col-6 text-center bonus">Bônus:<div class="value">{{ usuario.qtdade_bonus | num_format('0,0') }}</div></div>
                            </div>
                        </div>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li><router-link class="dropdown-item" :to="{ name: 'perfil', params: { tid: 'cadastro' } }">Minha conta</router-link></li>
                            <li><router-link class="dropdown-item fw-semibold" :to="{ name: 'comprar' }">Comprar lances</router-link></li>
                            <li><router-link class="dropdown-item" :to="{ name: 'perfil', params: { tid: 'meus-arremates' } }">Meus arremates</router-link></li>
                            <li><router-link class="dropdown-item" :to="{ name: 'perfil', params: { tid: 'alterar-senha' } }">Alterar senha</router-link></li>
                            <li><router-link class="dropdown-item" :to="{ name: 'perfil', params: { tid: 'minhas-indicacoes' } }">Indicar amigos</router-link></li>
                            <li><hr class="dropdown-divider" v-if="ehAdmin"></li>
                            <li><router-link class="dropdown-item" :to="{ name: 'admin', params: { tid: 'leiloes' } }" v-if="ehAdmin">Configurações</router-link></li>
                            <li><hr class="dropdown-divider"></li>
                            <li><a class="dropdown-item" href="#" @click.prevent="logout">Sair</a></li>
                        </ul>
                    </nav>                

                </div>

                <!-- header.menu -->
                <div class="col-5 col-sm-2 d-none justify-content-end d-sm-flex d-md-none align-items-center">
                    <button type="button" class="btn btn-link" @click="sideLeftClick">
                        <i class="ms-Icon ms-Icon--CollapseMenu"></i>
                    </button>
                </div>

            </div>
            </div>
        </header>

        <section :class="{'header-menu': true, 'd-none': !sideLeft, 'd-flex' : sideLeft, 'd-md-flex' : true}">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 offset-md-1">
                        <nav class="flex-column flex-md-row py-3 py-md-0">
                            <router-link :to="{ name: 'home'}" class="py-1 py-md-0">Home</router-link>
                            <router-link :to="{ name: 'arrematados'}" class="py-1 py-md-0">Leilões Arrematados</router-link>
                            <router-link :to="{ name: 'como-funciona'}" class="py-1 py-md-0">Como Funciona</router-link>
                            <router-link :to="{ name: 'faq'}" class="py-1 py-md-0">Perguntas Frequentes</router-link>
                            <router-link :to="{ name: 'comprar'}" class="py-1 py-md-0 destaque">Comprar Lances</router-link>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <div :class="{ 'header-shawdow opacity-75': true, 'd-none': !sideLeft, 'd-block': sideLeft, 'd-md-none': true }" @click="sideLeftClick"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { PER_ADMIN } from '@app/modules/permissoes';

export default {
    computed: {
        ...mapState('auth', [ 'user' ]),

        usuario() {
            return (this.user && this.user.id) ? this.user : null;
        },

        ehAdmin() {
            return false;
            //return (this.user && this.user.permissoes) ? (this.user.permissoes >= PER_ADMIN) : false;
        }
    },

    data() {
        return {
            sideLeft: false,
        }
    },

    methods: {
        async logout() {
            await this.$auth.logout();

            document.location.reload();
        },

        sideLeftClick() {
            this.sideLeft = !this.sideLeft;
        }
    }
}
</script>