
/**
 * Controle de rotas com permissoes.
 */
module.exports = (router, opts = {}) => {

    // Verificar se foi definido o controle de auth pelo options do router
    const auth = router.options.auth ? router.options.auth : null;

    // Registrar middleware em todas as rotas
    router.beforeEach(async (to, from, next) => {
        // Verificar se rota deve estar autenticada e com permissao especifica.
        // Caso nao necessite, continuar fluxo.
        if (!to.meta.can) {
            return next();
        }

        // Verificar se contexto NAO esta autenticado
        if ((!auth) || (!(await auth.check()))) {
            return router.replace({ name: 'error.404' });
        }

        // Verificar permissão
        var user = await auth.user();
        var req_permissao = to.meta.can;
        var user_permissao = user.permissoes ? user.permissoes : 0;

        if (user_permissao < req_permissao) {
            return router.replace({ name: 'error.404' });            
        }

        return next();
    });
};