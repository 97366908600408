<template>
  <div class="hora-servidor">
    {{ horaServidor | date_format('dddd, DD de MMMM de YYYY - HH:mm:ss') }}
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('app', ['horaServidor']),
    }
}
</script>