<template>
    <div class="status-socket-box">
        <div class="status-socket">
            <div class="status status-ok" v-if="serverConectado" title="Servidor on-line"></div>
            <div class="status status-error" v-if="!serverConectado" title="Servidor off-line"></div>
            <div class="label">
                Situação do servidor
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('leiloes', ['serverConectado']),
    }
}
</script>