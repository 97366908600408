import sdk from "@app/modules/sdk";

export default {
    namespaced: true,

    state: {
        parques: [],
        parques_destaques: [],
    },

    mutations: {

        SET_PARQUES(state, value) {
            state.parques = value;

            // Tratar destaques
            state.parques_destaques = [];
            for (var i = 0; i < value.length; i++) {
                state.parques_destaques.push(value[i]);
                if (i >= 5) {
                    break;
                }
            }
        },
    },

    actions: {
        async carregar({ commit}) {
            var parques = await sdk.get('portal/parques');

            commit('SET_PARQUES', parques);
        },
    }
};