module.exports = {
    // Auth
    auth: {
        msg: {
            usuario_senha_incorreto                : "Usuário e/ou senha incorretos.",
            usuario_nao_ativo                      : "Usuário {email} não esta ativo.",
            token_nao_encontrado                   : "Código de validação {token} não foi encontrado.",
            token_usuario_nao_encontrado           : "Código de validação não foi encontrado.",
            senha_nao_confere                      : "Conferência da senha digitada não confere",
            confirmar_usuario_token_nao_encontrado : "Código de validação do usuário não foi encontrado.",
            confirmar_usuario_token_invalido       : "Código de validação do usuário não foi encontrado.",
            confirmar_usuario_nao_encontrado       : "Código de validação do usuário não foi encontrado.",
            credencial_nao_confere                 : "Senha não confere",
            usuario_nao_logado                     : "Usuário não esta logado",
        }
    },

    // Leilão
    leilao : {
        msg: {
            nao_encontrado                       : "Leilão não encontrado",
            usuario_nao_logado                   : "Usuário não esta logado",
            tempo_esgotado                       : "Cronômetro do leilão terminou",
            segundos_invalido                    : "Lance inválido",
            usuario_ja_deu_ultimo_lance          : "Você já deu o último lance",
            usuario_sem_lance                    : "Que pena, você não tem mais lance, adquira agora mesmo.",
            debitar_lance_usuario_nao_encontrado : "Usuário não encontrado",
            nao_terminou                         : "Leilão não terminou",
        }
    },

    // CHeckout
    checkout: {
        msg: {
            produto_nao_encontrado : "Pacote que lances não foi encontrado",
            leilao_nao_encontrado  : "Leilão não foi encontrado",
            pagamento_nao_encontrado : "Pagamento não foi encontrado",
            pagamento_transacao_nao_corresponde : "ID da transação não corresponde",
        },

        mercado_pago: {
            msg: {
                meio_de_pagamento_nao_implementado : "Meio de pagamento não implementado",
            }
        }
    },

    // Webhook
    webhook: {
        msg: {
            erro_evento : "Erro ao disparar evento",
        }
    },

    // Validação
    validacao: {
        title: 'Erro ao aplicar alterações, verifique os campos abaixo',
        regra: {
            cpf               : "CPF inválido",
            email             : "E-mail inválido",
            senha_confirmacao : "Confirmação da senha não confere com a senha informada",
            len               : "Quantidade mínima de digitos não atingida"
        }
    },

};