/**
 * Controle de rotas autenticadas PREPARE.
 */
module.exports = (router, opts = {}) => {

    // Registrar middleware em todas as rotas
    router.beforeEach(async (to, from, next) => {

        // Carregar usuario logado
        //console.log('TOP AUTH PREPARE');
        //console.log('------------------------------------------------');
        // Forçar o carregamento do access_token
        router.app.$auth.$provider.getAccessToken();

        // carregar usuario logado
        await router.app.$store.dispatch('auth/carregar');
        //console.log('------------------------------------------------');

        return next();
    });
};