import Vue  from 'vue'
import App  from './app.vue';

Vue.config.productionTip = false;

//
// Registrar modulos
//
import auth     from '@app/modules/auth';
import router   from '@app/modules/router';
import Theme    from  '@app/modules/theme';
import views    from  '@app/modules/views';
import store    from  '@app/modules/stores';
import i18n     from  '@app/modules/i18n';
import storage  from  '@app/modules/storage';
import sdk      from  '@app/modules/sdk';

//
// Iniciar aplicação inicial
//
Theme().then((theme) => {

    window.app = new Vue({
        auth,
        router,
        theme,
        views,
        store,
        i18n,
        storage,
        sdk,
        el: "#app",
    
        render: h => h(App),
    });

});