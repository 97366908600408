<template>
    <div class="lance-cmd" v-if="leilao">

        <div class="contador">
            <app-contador ref="contador" :segundos="leilao.segundos" :max-segundos="leilao.cronometro_max" :ativo="leilao.ativo" :tamanho="tamanho"/>
        </div>

        <slot></slot>

        <div class="lance">
            <div class="d-grid gap-2">
                <t-button v-if="!leilao.arrematado" type="button" class="btn btn-primary" :carregando="registrando" mensagem="Registrando" @click="lance">DAR LANCE</t-button>
                <div v-if="leilao.arrematado" class="btn btn-secondary">ARREMATADO</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        leilao: {
            required: true,
        },

        tamanho: {
            default: 'normal',
        },
    },

    data() {
        return {
            registrando:  false,
        }
    },

    watch: {
        registrandoLance(newValue) {
            //console.log(newValue);
            if (!newValue) {
                this.registrando = false;
            }
        }
    },

    computed: {
        ...mapState('leiloes', ['registrandoLance']),
    },

    methods: {        
        ...mapActions('leiloes', ['darLance']),

        /**
         * Registrar lance
         */
        async lance() {
            this.registrando = true;
            try {
                await this.darLance({
                    leilao_id: this.leilao.id,
                    segundos : this.leilao.segundos,
                });

                //this.registrando = false;
            } catch (err) {
                this.registrando = false;
                throw err;
            }
        }
    }
}
</script>