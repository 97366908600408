import Vue from 'vue'
import VueI18n from 'vue-i18n';
import { messages } from '@root/langs/index';

// Registrar modulo vue
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'pt_br',
    fallbackLocale: 'pt_br',
    messages,
})

export default i18n;