<template>
    <div class="paginacao">
        <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">

                <li :class="{ 'page-item': true, 'disabled': !primeira }">
                    <router-link class="page-link" :to="primeira ? primeira : {}">
                        <span class="ms-Icon ms-Icon--ChevronLeftEnd6" />
                    </router-link>
                </li>

                <li :class="{ 'page-item': true, 'disabled': !voltar }">
                    <router-link class="page-link" :to="voltar ? voltar : {}">
                        <span class="ms-Icon ms-Icon--ChevronLeftSmall" />
                    </router-link>
                </li>

                <li v-for="pg in paginas" :key="pg.page" class="page-item">
                    <router-link :class="{ 'page-link': true, 'active': (pg.page == page) }" :to="pg.route">
                        {{ pg.label }}
                    </router-link>
                </li>

                <li :class="{ 'page-item': true, 'disabled': !proximo }">
                    <router-link class="page-link" :to="proximo ? proximo : {}">
                        <span class="ms-Icon ms-Icon--ChevronRightSmall" />
                    </router-link>
                </li>

                <li :class="{ 'page-item': true, 'disabled': !ultima }">
                    <router-link class="page-link" :to="ultima ? ultima : {}">
                        <span class="ms-Icon ms-Icon--ChevronRightEnd6" />
                    </router-link>
                </li>

            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            required: true,
        },

        totalRecs: {
            required: true,
        },

        recsPorPagina: {
            default: 10,
        }
    },

    data() {
        return {
            paginas : [],

            primeira : null,
            voltar   : null,
            proximo  : null,
            ultima   : null,
        };
    },
    
    mounted: function () {
        this.montar();
    },

    watch: {
        page(newPg) {
            this.montar();
        }
    },

    methods: {
        montar() {
            // Montar paginas
            this.paginas     = [];
            var numPagesTela = 10;
            var totalPages   = Math.ceil(this.totalRecs / this.recsPorPagina);

            var pages = (totalPages > numPagesTela) ? numPagesTela : totalPages;
            var min   = 0;


            for (var i = min;  i < pages; i++) {

                this.paginas.push({
                    page  : i,
                    label : i + 1,
                    route : this.getRoutePage(i),
                });
            }

            // Voltar e Primeira
            this.primeira = null;
            this.voltar  = null;
            if (this.page > 0) {
                this.primeira = this.getRoutePage(0)
                this.voltar   = this.getRoutePage(this.page - 1);
            }

            // Proximo e Ultima
            this.ultima  = null;
            this.proximo = null;
            if (this.page < (this.paginas.length-1)) {
                this.ultima  = this.getRoutePage(this.paginas.length-1);
                this.proximo = this.getRoutePage(this.page + 1);                
            }
        },

        getRoutePage(pg) {
            return { name: this.$route.name, params: this.$route.params, query: { p: pg } };
        }
    }
};
</script>