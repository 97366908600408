import Vue       from 'vue'
import VueRouter from 'vue-router';
import routes    from "@app/routes";
import framework from 'rhinojs/webcore';
import storage   from  '@app/modules/storage';
import auth      from  '@app/modules/auth';
const { pageTitle } = require('./utils/title');


import middlewareAuthPrepare from  '@app/modules/middlewares/auth';
import middlewareAuthCan from  '@app/modules/middlewares/can';

// Registrar modulo vue
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes,
    auth,
});

//
// Add $url
//
var url = new framework.url(router);
Vue.use(url);

//
// Add $title
//
var Title = {
    install: function(Vue) {
        Vue.prototype.$title = pageTitle;
    }
};
Vue.use(Title);

//
// Adicionar controle de middlewares.
//
middlewareAuthPrepare(router);
framework.middlewares.auth(router, storage, url);
framework.middlewares.guest(router);
middlewareAuthCan(router)
framework.middlewares.title(router, window.APP_TITLE ? window.APP_TITLE : 'LeilaoIngressos.com');
framework.middlewares.totop(router);

export default router;