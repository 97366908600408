<template>
    <div id="app" :class="{'t-scroll-fixed': scrollFixed }">
        <router-view />
    </div>
</template>

<script>

export default {
    name: "App",

    data() {
        return {
            scrollFixed: false,
        }
    },

    created () {
        //console.log('INICIO');
        //console.log(document.location);
        //console.log('-------------------------------');
        window.addEventListener('scroll', this.handleScroll);

        this.$store.dispatch('app/iniciar');
        this.$store.dispatch('leiloes/iniciar');
        this.$store.dispatch('carrinho/carregarProdutos');
        this.$store.dispatch('parques/carregar');
    },

    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        //...mapActions("mundos", ['carregar_listas','carregar_state']),        
        handleScroll(event) {
            this.scrollFixed = (window.scrollY >= 70);
        }
    },
};
</script>

<style lang="sass">
@import "@theme/scss/app.scss";
</style>