<template>
    <div class="pagina_norecs">

        <h1>
            <i :class="'ms-Icon ms-Icon--' + icone"/>
        </h1>

        <h3 class="fw-light mt-2">{{ titulo }}</h3>
        <p v-if="desc" v-html="desc"></p>
    </div>
</template>

<script>
export default {
    props: {
        icone: {
            default: 'Warning'
        },

        titulo: {
            required: true,
        },

        desc: {
            default: null
        }
    }
}
</script>