<template>
    <div class="t-layout">

        <t-header />

        <div class="container t-layout-content">
            <div class="row">
                <div class="col-12 col-xl-10 offset-xl-1">
                    <slot></slot>
                </div>
            </div>
        </div>

        <t-footer />
    </div>
</template>

<script>
import THeader from './header.vue';
import TFooter from './footer.vue';

export default {
    components: {
        THeader,
        TFooter,
    }
}
</script>