import Swal from 'sweetalert2/dist/sweetalert2.js';
import sdk from "@app/modules/sdk";
import i18n from "@app/modules/i18n";
const { io } = require("socket.io-client");
const { EventEmitter } = require('events');

export class LeilaoSocket
{
    constructor() {

        // Parametros
        var socket_endpoint = window.APP_WEBSEOCKET_ENDPOINT;
        console.log('%cSOCKET INICIAR...: ' + socket_endpoint, 'font-weight: bold; color: green;');

        this.events = new EventEmitter();

        // Iniciar socket
        this.$socket = io(socket_endpoint);

        // COnfigurar o socket
        this.setup();
    }

    /**
     * Enviar o access_token par ao socket
     */
    setToken() {
        this.$socket.emit('set_token', { token: sdk.getAccessToken() });
    }

    /**
     * Enviar comando do lance para o socket server.
     * 
     * @param {*} leilao_id 
     * @param {*} segundos 
     * @returns {object}
     */
    async darLance(leilao_id, segundos) {

        //console.log('LANCE', args);

        return await this.$socket.emit('lance', { leilao_id, segundos });
    }

    /**
     * Quando cliente se conecta no socket.
     */
    connect() {
        console.log(' - cliente entrou');
            
        // Enviar o access_token do SDK logado para o socket
        this.setToken();

        this.events.emit('entrou');
    }

    /**
     * Quando o cliente se desconecta do socket.
     */
    disconnect() {
        console.log(' - cliente saiu');
        this.events.emit('saiu');
    }

    /**
     * Quando é recebido uma atualização dos leiloes.
     * @param {*} data 
     */
    leilaoUpdate(data) {
        //console.log(' - atualizacao dos leiloes', data);
        this.events.emit('leilao-update', data);
    }

    /**
     * Quando o leilao acabou.
     * @param {*} data 
     */
    leilaoAcabou(data) {
        console.log(' - leilao ACABOU ', data);
    }

    /**
     * Quando o usuário atual recebe uma atualização dos lances.
     * @param {*} data 
     */
    lancesUsuarioUpdate(data) {
        //console.log(' - atualizacao dos lances ', data);
        this.events.emit('usuario-lances-udate', data);
    }

    /**
     * Quando o socket envia um erro.
     * @param {*} erro 
     */
    erro(erro) {
        //console.log(' ERRO: ', erro);

        this.events.emit('erro', erro);

        Swal.fire({
            title: erro.id ? i18n.t(erro.id) : 'Erro interno',
            icon: 'error',
        });
    }

    /**
     * Configurar socket.
     */
    setup() {
        //var $this = this;
        this.$socket.on('connect',               () => this.connect());
        this.$socket.on('disconnect',            () => this.disconnect());
        this.$socket.on('leiloes-update',        (data) => this.leilaoUpdate(data));
        this.$socket.on('leilao-acabou',         (data) => this.leilaoAcabou(data));
        this.$socket.on('lances-usuario-update', (data) => this.lancesUsuarioUpdate(data));
        this.$socket.on('erro',                  (erro) => this.erro(erro));
    }
}