import Vue          from 'vue';
import Client       from "./sdk/client";
import sdkConsts    from "./sdk/config";
import arr          from 'rhinojs/support/arr';

var configEnv = arr.get(window, 'APP_SDK_ENV', sdkConsts.envProduction);


// Ambiente Padrão
var sdkEnv = sdkConsts.envProduction;
// Ambiente Sandbox
sdkEnv = (configEnv == 'sandbox') ? sdkConsts.envSandbox : sdkEnv;
// Ambiente Local
sdkEnv = (configEnv == 'local') ? sdkConsts.envLocal : sdkEnv;

console.log('SDK ENV: %c' + sdkEnv, 'color: blue; font-weight: bold;');

// Clients
const client = new Client({ 
    env: sdkEnv,
 });

/**
 * Instalar use no Vue.
 * 
 * @param {Vue} Instancia do Vue
 */
client.install = function(Vue) {
    Vue.prototype.$sdk = this;
    Vue.sdk = this;
};

// Registrar modulo vue
Vue.use(client);

export default client;