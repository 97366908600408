import Vue               from 'vue';
import framework         from "rhinojs/webcore";
import arr               from 'rhinojs/support/arr';
import AuthProvider      from 'rhinojs/webcore/auth/provider';
import sdk               from "@app/modules/sdk";
import storage           from "@app/modules/storage";
import stores            from "@app/modules/stores";
import store             from './stores';

class AppAuthProvider extends AuthProvider
{
    /**
     * Construtor.
     */
    constructor() {
        super();

        this.$sdk             = sdk;
        this.$storage         = storage;
        this.$store           = stores;

        this.$storagePrefix   = arr.get(window, 'APP_STORE_PREFIX',   '__li_');
        this.$cookieDomain    = arr.get(window, 'APP_COOKIE_DOMAIN',  '.netforce.com.br');
        this.$cookieExpires   = arr.get(window, 'APP_COOKIE_EXPIRES', '3Y');

        // Carregar configurações do cookie
        this.$cookieOpts = {
            expires: this.$cookieExpires,
            domain : this.$cookieDomain,
        };

        // Gerar ids
        this.storageId = this.$storagePrefix + 'mai_rem';  // email remember
        this.cookieId  = this.$storagePrefix + 'tki';  // access_token

    }

    /**
     * Retorna o access token do usuario da sessao.
     * 
     * @returns {String|null}
     */
    getAccessToken() {
        // Verificar se veio via cookie
        var tk_cookie = this.$storage.cookie.get(this.cookieId);
        if (tk_cookie) {
            this.$sdk.setAccessToken(tk_cookie);
            return tk_cookie;
        }

        return null;
    }

    /**
     * Informar o token de acesso.
     * 
     * @param {String|null} token Token de acesso
     */
    setAccessToken(token) {
        this.$sdk.setAccessToken(token);

        // Tratar cookie
        if (token) {
            this.$storage.cookie.set(this.cookieId, token, this.$cookieOpts);
        } else {
            this.$storage.cookie.remove(this.cookieId, this.$cookieOpts);
        }

        // Tratar socket
        store.dispatch('leiloes/setToken');
    }

    /**
     * Informar o email guardado na sessao.
     * 
     * @param {String|null} token Token de acesso
     */
    getEmailRememberSession() {
        return this.$storage.local.get(this.storageId);
    }

    /**
     * Guardar o email na sessao.
     * 
     * @param {String|null} email Email a guardar
     */
    setEmailRememberSession(email) {
        if (email) {
            this.$storage.local.set(this.storageId, email);
        } else {
            this.$storage.local.remove(this.storageId);
        }
    }

    /**
     * Fazer login.
     * 
     * @param {String} email do usuario
     * @param {String} senha Senha
     */
    async login(email, senha)
    {
        return await this.$sdk.post('auth/login', { email, senha });
    }

    /**
     * Fazer logout.
     */
    async logout()
    {
        var token = this.getAccessToken();

        await this.$sdk.post('auth/logout', { token });

        this.setAccessToken(null);

        return true;
    }

    /**
     * Carregar informações do usuário logado.
     */
    async me(inLogin)
    {
        console.log('AUTH - ME ', inLogin);        
        //if ((this.$store.state.auth.user) && (this.$store.state.auth.user.id)) {
        // Depois verificar como ver se o token morreu no servidor
        if ((this.$store.state.auth.user)) {
            return this.$store.state.auth.user;
        }
        
        // Carregar usuario da API
        throw 'usuario.nao.logado';
    }
    
    /**
     * Gerar token para trocar de senha sem estar logado.
     * 
     * @param {String} email Email do usuário
     * @param {String} url URL do formulário de reset.
     */
    async forgotPassword(email, url)
    {
        return await this.$sdk.post('auth/esqueci-senha', { email, url });
    }
    
    /**
     * Trocar de senha sem estar logado.
     * 
     * @param {String} token Token de autorização para trocar senha
     * @param {String} novaSenha Nova senha
     */
    async resetPassword(token, novaSenha)
    {
        return await this.$sdk.post('auth/reiniciar-senha', { token, senha: novaSenha });
    }
}

// ---------------------------------------------------------------------
// Carregar Auth
// ---------------------------------------------------------------------
const auth = new framework.auth(new AppAuthProvider());

// Regitsrar auth no vue
Vue.use(auth);

export default auth;