<template>
    <footer class="d-print-none">
        <div class="container py-4 py-md-2">
            <div class="row">

                <div class="col-6 col-sm-4 col-lg-3 text-end text-sm-start">
                    <h6>Leilão de Ingressos</h6>
                    <ul>
                        <li>
                            <router-link :to="{ name: 'andamento' }">Próximos leilões</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'arrematados' }">Arrematados</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'comprar' }">Adiquirir lances</router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-6 col-sm-4 col-lg-3">
                    <h6>Como funciona</h6>
                    <ul>
                        <li>
                            <router-link :to="{ name: 'como-funciona' }">Passo a passo</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'faq' }">Perguntas frequentes</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'termos' }">Termos e condições</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'perfil', params: { tid : 'minhas-indicacoes'} }">Indique um amigos e ganhe bonus</router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-sm-4 col-lg-3 text-center text-sm-start">
                    <h6>Contato e Suporte</h6>
                    <ul>
                        <li class="mb-2">
                            Entre em contato com nosso suporte.<br>
                            <a href="https://leilaoingressos.atlassian.net/servicedesk/customer/portals" target="suporte">Nosso suporte</a>
                        </li>
                        <li>
                            <app-status-socket />
                        </li>
                    </ul>
                    <div>
                        Horários de atendimento:<br>
                        {{atendimento}}
                    </div>

                </div>

                <div class="col-12 col-lg-3 text-center text-lg-start">
                    <h6>Alguns parques <small>( <router-link to="/parques">Ver todos</router-link> )</small></h6>

                    <div class="mb-4 parques">

                        <router-link v-for="parque in parques_destaques" :key="parque.id" :to="{ name: 'parque', params: { pns: parque.ns } }" :title="parque.nome">
                            <img :src="parque.logo_src">
                        </router-link>

                    </div>

                    <h6>Formas de Pagamento</h6>
                    <div class="mb-4 formas-pagto">
                        <img src="https://static.netforce.com.br/leilaoingressos/imgs/mp_formas.png">
                    </div>
                </div>


            </div>

            <div class="row infos">
                <div class="col-12 col-md-4 text-center text-md-start pb-2">
                    <b>Leilao de Ingressos</b> - Site de leilão de ingressos.                    
                </div>

                <div class="col-12 col-md-4 text-center pb-2">
                    Limite de 10 arremates por mês por CPF                    
                </div>

                <div class="col-12 col-md-4 text-center text-md-end pb-2">
                    2023 - Todos os direitos reservados <small><br>v {{ versao }}</small>
                </div>
            </div>

        </div>
    </footer>
</template>

<script>
import config from '@root/package.json';
import configWeb from '@root/web.config.json';
import str from 'rhinojs/support/str';
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('parques', [ 'parques_destaques' ]),

        versao() {
            return config.version;
        },

        cnpj() {
            return configWeb.cnpj;
        },

        endereco() {
            return configWeb.endereco ? configWeb.endereco : '';
        },

        email() {
            return configWeb.email ? configWeb.email : '';
        },

        whatsapp() {
            return configWeb.whatsapp ? configWeb.whatsapp : '';
        },

        whatsappHref() {
            var numero = this.whatsapp.replace(/[^0-9]/g, '');
            return 'https://web.whatsapp.com/send?phone=' + numero;
        },

        atendimento() {
            return configWeb.atendimento ? configWeb.atendimento : '';
        }
    }
}
</script>